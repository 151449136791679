import getGraphQlInstance from "../config/graphQlConfig";
import {
  FETCH_BLOGS,
  FETCH_CONTENT_DETAIL,
  FETCH_CONTENT_SCHEMA_DETAIL,
  FETCH_COOKIE,
  FETCH_FOOTER,
  FETCH_MENU,
  FETCH_POLL,
  FETCH_TAG,
  GET_CHALLENGE_DETAILS,
  GET_CONTENT_SCHEMA_LIST,
  GET_EVENT_DETAILS,
  GET_PERSONAL_TRACKER,
} from "./publishQueries";

const getApi = {
  fetchCookie: async <T>(input: T, lang, host): Promise<any> => {
    const data = await getGraphQlInstance(lang, host).query({
      query: FETCH_COOKIE,
      variables: input,
      fetchPolicy: "network-only",
    });
    return data;
  },
  fetchFooter: async <T>(input: T, lang, host): Promise<any> => {
    const data = await getGraphQlInstance(lang, host).query({
      query: FETCH_FOOTER,
      variables: input,
      fetchPolicy: "network-only",
    });
    return data;
  },
  fetchMenu: async <T>(input: T, lang, host): Promise<any> => {
    const data = await getGraphQlInstance(lang, host).query({
      query: FETCH_MENU,
      variables: input,
      fetchPolicy: "network-only",
    });
    return data;
  },
  fetchContentDetail: async <T>(input: T, lang, host): Promise<any> => {
    const data = await getGraphQlInstance(lang, host).query({
      query: FETCH_CONTENT_DETAIL,
      variables: input,
      fetchPolicy: "network-only",
    });
    return data;
  },
  fetchTag: async <T>(input: T, lang, host): Promise<any> => {
    const data = await getGraphQlInstance(lang, host).query({
      query: FETCH_TAG,
      variables: input,
      fetchPolicy: "network-only",
    });
    return data;
  },
  fetchPoll: async <T>(input: T, lang, host): Promise<any> => {
    const data = await getGraphQlInstance(lang, host).query({
      query: FETCH_POLL,
      variables: input,
      fetchPolicy: "network-only",
    });
    return data;
  },
  fetchBlogs: async <T>(input: T, lang, host): Promise<any> => {
    const data = await getGraphQlInstance(lang, host).query({
      query: FETCH_BLOGS,
      variables: input,
      fetchPolicy: "network-only",
    });
    return data;
  },
  fetchContentSchemaDetail: async <T>(input: T, lang, host): Promise<any> => {
    const data = await getGraphQlInstance(lang, host).query({
      query: FETCH_CONTENT_SCHEMA_DETAIL,
      variables: input,
      fetchPolicy: "network-only",
    });
    return data;
  },
  getContentSchemaList: async <T>(input: T, lang, host): Promise<any> => {
    const data = await getGraphQlInstance(lang, host).query({
      query: GET_CONTENT_SCHEMA_LIST,
      variables: input,
      fetchPolicy: "network-only",
    });
    return data;
  },
  getEventDetails: async <T>(input: T, lang, host): Promise<any> => {
    const data = await getGraphQlInstance(lang, host).query({
      query: GET_EVENT_DETAILS,
      variables: input,
      fetchPolicy: "network-only",
    });
    return data;
  },
  fetchPersonalTracker: async <T>(input: T, lang, host): Promise<any> => {
    const data = await getGraphQlInstance(lang, host).query({
      query: GET_PERSONAL_TRACKER,
      variables: input,
      fetchPolicy: "network-only",
    });
    return data;
  },
  getChallengeDetails: async <T>(input: T, lang, host): Promise<any> => {
    const data = await getGraphQlInstance(lang, host).query({
      query: GET_CHALLENGE_DETAILS,
      variables: input,
      fetchPolicy: "network-only",
    });
    return data;
  },
};

export default getApi;
