import { gql } from "@apollo/client";

export const FETCH_COOKIE = gql`
  query FETCH_COOKIE($pagePath: String!) {
    publish_fetchMultiSiteCookieSettings(pagePath: $pagePath, contentType: MultiSiteSettings) {
      cookie_policy_cta_link
      non_essential_cookie_description
      cookie_manage_setting_consent_button
      cookie_button_text
      non_essential_cookie_title
      manage_save_setting_consent_button
      essential_cookie_description
      consent_cookie_button
      manage_setting_description
      consent_cookie_title
      manage_setting_cookie_button
      cookie_description
      cookie_manage_setting_title
      essential_cookie_title
      consent_cookie_description
      cookie_policy_cta_text
      cookie_title
      consent_cookie_policy_link
      informative_cookie_policy_link
      informative_cookie_country_list
      consent_cookie_country_list
      cookie_consent_expiry_time
      cookie_informative_expiry_time
    }
  }
`;

export const FETCH_FOOTER = gql`
  query FETCH_FOOTER($pagePath: String!) {
    publish_fetchMultiSiteFooterSettings(pagePath: $pagePath, contentType: MultiSiteSettings) {
      about_us_text
      address
      contact_number
      copyright_text
      email_address
      news_letter_description
      news_letter_title
      site_logo
      title_text
      fav_icon
      link
      footermediahandle
      footer_text_colour
      header_text_colour
      header_bg_colour
      primary_colour
      footer_bg_colour
      font_selection
      secondary_colour
      header_logo
      search
      language
      cta_title
      cta_url
      languages
      icon_setting
    }
  }
`;

export const FETCH_MENU = gql`
  query FETCH_MENU($pagePath: String!) {
    publish_fetchMenu(pagePath: $pagePath) {
      Title
      Tagging
      Description
      Menu_Id
      ParentId
      Menu_State
      URL
      Label
      Internal
      Status
      Score
      IsHidden
      HomePage
      lastModifiedBy
      Menu_PublishedBy
      Author
      Menu_PublishedDate
      LastPublishedDate
      createdBy
      UserActionInfo
      content_type_value
      Submenu
      menuicon
      IsCurrentTab
    }
  }
`;
export const FETCH_CONTENT_DETAIL = gql`
  query FETCH_CONTENT_DETAIL($pagePath: String!, $contentType: publish_ContentTypes!) {
    publish_contentDetail(pagePath: $pagePath, contentType: $contentType)
  }
`;

export const FETCH_TAG = gql`
  query FETCH_TAG($pagePath: String!) {
    publish_fetchSiteSettings(pagePath: $pagePath, contentType: MultiSiteSettings) {
      site_assigned_content_types
      site_assigned_tags
    }
  }
`;

export const FETCH_POLL = gql`
  query FETCH_POLL($title: String!) {
    users_fetchContent(title: $title) {
      title
      document_path
      options
      status
      total_vote
      start_date
      end_date
      created_by
      updated_by
      createdAt
      updatedAt
    }
  }
`;

export const FETCH_BLOGS = gql`
  query FETCH_BLOGS($input: publish_createBlogInput) {
    publish_fetchblog(input: $input)
  }
`;

export const FETCH_BLOG_BY_ID = gql`
  query FETCH_BLOG_BY_ID($input: publish_createBlogInput) {
    publish_fetchblogById(input: $input)
  }
`;

export const FETCH_CONTENT_SCHEMA_DETAIL = gql`
  query FETCH_CONTENT_SCHEMA_DETAIL($contentType: String!, $pagePath: String!) {
    publish_fetchSchemaContent(contentType: $contentType, pagePath: $pagePath)
  }
`;

export const GET_CONTENT_SCHEMA_LIST = gql`
  query GET_CONTENT_SCHEMA_LIST($searchTerm: String!, $filter: String!) {
    publish_getContents(searchTerm: $searchTerm, tags: [], filter: $filter, isSuggestive: false)
  }
`;

export const GET_EVENT_LIST = gql`
  query GET_EVENT_LIST($input: String!) {
    publish_getContents(searchTerm: $searchTerm, tags: [], filter: $filter, isSuggestive: false)
  }
`;

export const GET_EVENT_DETAILS = gql`
  query GET_EVENT_DETAILS($event_id: String!, $user_id: String) {
    community_eventDetails(event_id: $event_id, user_id: $user_id)
  }
`;
export const GET_PERSONAL_TRACKER = gql`
  query GET_PERSONAL_TRACKER($date: String!, $user_id: String) {
    community_fetchPersonalTracker(date: $date, user_id: $user_id)
  }
`;

export const GET_CHALLENGE_DETAILS = gql`
  query GET_CHALLENGE_DETAILS($challenge_id: String!, $user_id: String) {
    community_challengesDetail(challenge_id: $challenge_id, user_id: $user_id) {
      total_participant
      is_joined
      user_steps
      is_device_synced
    }
  }
`;
